import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, List, ListItem, styled, Tooltip, Typography } from '@mui/material';
import { ChatbotFAQ, ChatList } from 'src/@types/apiResponseTypes';

import {
  CurrentChatRoomIdState,
  isChatbotFAQType,
  isChatListType,
  OnDeleteChatRoom,
} from '../../utils/types';

/** ChatListItemBox */
interface ChatListItemBoxProps {
  subtitle: {
    text: string;
    icon?: React.ReactNode;
  };
  chatRooms: ChatList[] | ChatbotFAQ[];
  currentChatRoomIdState: CurrentChatRoomIdState;
  onCloseDrawer?: () => void;
  onDeleteChatRoom?: OnDeleteChatRoom;
}

function ChatListItemBox({
  subtitle,
  chatRooms,
  currentChatRoomIdState,
  onCloseDrawer,
  onDeleteChatRoom,
}: ChatListItemBoxProps) {
  const [removeAllChatYn, setRemoveAllChatYn] = useState<boolean>(false);

  const { text, icon } = subtitle;
  const onDeleteAllChatRooms = onDeleteChatRoom?.onDeleteAllChatRooms;
  const onDeleteSingleChatRoom = onDeleteChatRoom?.onDeleteSingleChatRoom;

  return (
    <Box sx={{ width: '100%', paddingX: '4px', borderBottom: '1px solid white' }}>
      <Box
        sx={{
          color: '#FFF8',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '6px 10px',
        }}
      >
        <StyledTypography variant="caption">{text}</StyledTypography>
        <IconButton
          size="small"
          sx={{ padding: 0 }}
          onClick={() => {
            setRemoveAllChatYn(true);
          }}
        >
          {removeAllChatYn ? (
            <>
              <CheckIcon
                sx={{ fontSize: 18, color: 'white' }}
                onClick={(event) => {
                  event.stopPropagation();
                  onDeleteAllChatRooms && onDeleteAllChatRooms();
                  setRemoveAllChatYn(false);
                }}
              />
              <CloseIcon
                sx={{ fontSize: 18, color: 'white' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setRemoveAllChatYn(false);
                }}
              />
            </>
          ) : (
            <>{icon}</>
          )}
        </IconButton>
      </Box>
      <List sx={{ display: 'flex', flexDirection: 'column', rowGap: ' 3px', paddingTop: 0 }}>
        {chatRooms.length > 0 &&
          chatRooms.map((chatRoom) => (
            <ChatListItem
              key={Math.random()}
              chatRoom={chatRoom}
              currentChatRoomIdState={currentChatRoomIdState}
              onDeleteSingleChatRoom={onDeleteSingleChatRoom}
              onCloseDrawer={onCloseDrawer}
            />
          ))}
      </List>
    </Box>
  );
}

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: '30px',
  letterSpacing: '1.5px',
}));

/** ChatListItem */
interface ChatListItemProps {
  chatRoom: ChatList | ChatbotFAQ;
  currentChatRoomIdState: CurrentChatRoomIdState;
  onCloseDrawer?: () => void;
  onDeleteSingleChatRoom?: (chat_id: string) => void;
}

function ChatListItem({
  chatRoom,
  currentChatRoomIdState,
  onCloseDrawer,
  onDeleteSingleChatRoom,
}: ChatListItemProps) {
  const [removeChatYn, setRemoveChatYn] = useState<boolean>(false);
  const backgroundColor = '#d9dadb';
  const fontColorForHover = 'black';
  const { currentChatRoomId, onSetCurrentRoomId } = currentChatRoomIdState;
  const { onSetCurrentChatRoomId, onSetCurrentFAQRoomId } = onSetCurrentRoomId;

  if (isChatListType(chatRoom)) {
    const { chat_id, chat_title } = chatRoom;

    const isCurrentChatRoom = currentChatRoomId === chat_id;
    const newChat = chat_title.length === 0;

    return newChat ? null : (
      <Tooltip
        title={chat_title}
        placement="right"
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'zIndex',
              enabled: true,
              phase: 'main',
              fn: ({ state }) => {
                state.styles.popper.zIndex = '5000';
              },
            },
          ],
        }}
      >
        <ListItem
          key={chat_id}
          onClick={(event) => {
            event.stopPropagation();
            onSetCurrentChatRoomId(chat_id);
            onCloseDrawer && onCloseDrawer();
          }}
          sx={{
            color: isCurrentChatRoom ? 'black' : 'white',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: isCurrentChatRoom ? backgroundColor : '',
            borderRadius: '8px',
            padding: '6px 10px',
            ':hover': {
              color: fontColorForHover,
              backgroundColor: backgroundColor,
            },
          }}
        >
          <Typography variant="subtitle2">
            <CommentOutlinedIcon sx={{ fontSize: 18 }} /> {chat_title}
          </Typography>
          {/* Innerwave Guide 섹션이면 휴지통 아이콘 가리기 */}
          <IconButton size="small" sx={{ padding: 0 }}>
            {removeChatYn ? (
              <>
                <CheckIcon
                  sx={{ fontSize: 18 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteSingleChatRoom && onDeleteSingleChatRoom(chat_id);
                  }}
                />
                <CloseIcon
                  sx={{ fontSize: 18 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setRemoveChatYn(false);
                  }}
                />
              </>
            ) : (
              isCurrentChatRoom && (
                <DeleteOutlineIcon
                  sx={{ fontSize: 18 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setRemoveChatYn(true);
                  }}
                />
              )
            )}
          </IconButton>
        </ListItem>
      </Tooltip>
    );
  } else if (isChatbotFAQType(chatRoom)) {
    const { faq_id, question } = chatRoom;
    const stringifyFaqId = faq_id.toString();
    return (
      <Tooltip
        title={question}
        placement="right"
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'zIndex',
              enabled: true,
              phase: 'main',
              fn: ({ state }) => {
                state.styles.popper.zIndex = '5000';
              },
            },
          ],
        }}
      >
        <ListItem
          key={faq_id}
          onClick={() => {
            onSetCurrentFAQRoomId(faq_id);
            onCloseDrawer && onCloseDrawer();
          }}
          sx={{
            color: currentChatRoomId === stringifyFaqId ? 'black' : 'white',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: currentChatRoomId === stringifyFaqId ? backgroundColor : '',
            borderRadius: '8px',
            padding: '6px 10px',
            ':hover': {
              color: fontColorForHover,
              backgroundColor: backgroundColor,
            },
          }}
        >
          <Typography variant="subtitle2">
            <CommentOutlinedIcon sx={{ fontSize: 18 }} />{' '}
            {question.length > 10 ? `${question.substring(0, 10)} ...` : question}
          </Typography>
        </ListItem>
      </Tooltip>
    );
  } else {
    return null;
  }
}

export default ChatListItemBox;
