import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Typography } from '@mui/material';
import { ChatbotFAQ, ChatList } from 'src/@types/apiResponseTypes';

import useToggleDrawer from '../../hooks/useToggleDrawer';
import { CurrentChatRoomIdState, OnDeleteChatRoom } from '../../utils/types';

import ChatListItemBox from './ChatListItemBox';

interface ChatsSidebarProps {
  currentChatRoomIdState: CurrentChatRoomIdState;
  chatRoomsCollection: {
    chatRooms: ChatList[];
    innerwaveGuideChatRooms?: ChatbotFAQ[];
  };
  onOpenFeedbackModal: () => void;
  onDeleteChatRoom: OnDeleteChatRoom;
}

function ChatsSidebar({
  currentChatRoomIdState,
  chatRoomsCollection,
  onOpenFeedbackModal,
  onDeleteChatRoom,
}: ChatsSidebarProps) {
  const { chatRooms, innerwaveGuideChatRooms } = chatRoomsCollection;

  const toggleDrawer = useToggleDrawer();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        paddingX: '4px',
      }}
    >
      <Box>
        <ChatListItemBox
          subtitle={{
            text: 'CHAT LIST',
            icon: <DeleteOutlineIcon sx={{ color: '#00FF00', fontSize: 18 }} />,
          }}
          chatRooms={chatRooms}
          currentChatRoomIdState={currentChatRoomIdState}
          onCloseDrawer={toggleDrawer.handleDrawerClose}
          onDeleteChatRoom={onDeleteChatRoom}
        />
        <ChatListItemBox
          subtitle={{ text: 'INNERWAVE GUIDE' }}
          chatRooms={innerwaveGuideChatRooms || []}
          currentChatRoomIdState={currentChatRoomIdState}
          onCloseDrawer={toggleDrawer.handleDrawerClose}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '15px 10px',
        }}
      >
        <Button
          sx={{
            width: '100%',
            height: '40px',
            border: '2px solid #FFF',
            borderRadius: '20px',
            color: 'white',
          }}
        >
          <Typography variant="button" onClick={onOpenFeedbackModal}>
            의견 보내기
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default ChatsSidebar;
