import React from 'react';
import { Box } from '@mui/material';
import innerMateLog from 'src/assets/images/inner_mate_logo.png';

import { SIDEBAR_BACKGROUND_COLOR } from '../../utils/contants';

function SidebarForDesktop({
  children,
  resizableChatWindowWidth,
  newButtonComp,
  onMouseDown,
}: {
  children: React.ReactNode;
  resizableChatWindowWidth: number;
  newButtonComp: React.ReactNode;
  onMouseDown: (e: React.MouseEvent) => void;
}) {
  const resizableSidebarWidth = resizableChatWindowWidth / 3;

  return (
    <Box
      onMouseDown={(e) => {
        const leftBorderArea = e.nativeEvent.offsetX < 5;
        // const rightBorderArea = e.nativeEvent.offsetX > resizableSidebarWidth - 5;
        // 사이드바 전체가 아닌 사이드바의 왼쪽 border 근처에서만 resize 가능하게 함
        if (leftBorderArea) {
          onMouseDown(e);
        }
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: `${resizableSidebarWidth}px`,
        height: '100%',
        overflow: 'hidden',
        backgroundColor: SIDEBAR_BACKGROUND_COLOR,
        alignItems: 'flex-start',
        position: 'relative',
        borderLeft: '1px solid rgb(216, 226, 253)',
        borderRight: '1px solid rgb(216, 226, 253)',

        // 사이드바의 border에 hovering 할 때
        // 1. 'cursor: ew-resize' 효과 적용 2. 반투명한 border가 보이게 하여 resize 가능하다는 것을 알림.
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          width: '5px',
          height: '100%',
          cursor: 'default',
          transition: 'background-color 0.3s',
        },
        '&:hover::before': {
          cursor: 'ew-resize',
          backgroundColor: 'rgba(223, 232, 242, 0.6)', // semi-transparent pink
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          background: '#1565C0',
          paddingY: '15px',
          paddingLeft: '15px',
          width: '100%',
          borderBottom: 'solid 1px #FFF',
        }}
      >
        <img src={innerMateLog} alt="innermate_logo" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          verticalAlign: 'middle',
          width: '100%',
          padding: '15px 10px 0',
        }}
      >
        {newButtonComp}
      </Box>
      {children}
    </Box>
  );
}

export default SidebarForDesktop;
